/**
 * Global Components
 */
import Vue from 'vue'

// import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import AppSectionLoader from "Components/AppSectionLoader/AppSectionLoader";
// import { RotateSquare2 } from "vue-loading-spinner";

// App Card component
import AppCard from 'Components/AppCard/AppCard'
import AppCardCustom from 'Components/AppCard/AppCardCustom'

// App Table component
import AppTable from 'Components/AppTable/AppTable'

// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar'

// App Picker component
import AppDatePicker from 'Components/AppPicker/AppDatePicker'
import AppTimePicker from 'Components/AppPicker/AppTimePicker'

// App Dialog component
import AppDialog from 'Components/AppDialog/AppDialog'

import AppSectionLoader from 'Components/AppSectionLoader/AppSectionLoader.vue'

import AppFileUpload from 'Components/FileUpload/file-upload.vue'

import AppHeader from 'Components/AppHeader/AppHeader'

import AppQrEcard from 'Components/QrEcard/QrEcard.vue'

import VueQRCodeComponent from 'vue-qrcode-component'

import Vuevcard from 'vue-vcard'
import * as VueGoogleMaps from 'vue2-google-maps'
/* eslint-disable @typescript-eslint/no-var-requires */
const VueEditor = require('vue2-editor')

/* eslint-disable @typescript-eslint/no-var-requires */
// const wysiwyg = require('vue-wysiwyg')

Vue.component('appCard', AppCard)
Vue.component('appCardCustom', AppCardCustom)
Vue.component('appTable', AppTable)
Vue.component('appDatePicker', AppDatePicker)
Vue.component('appTimePicker', AppTimePicker)
Vue.component('appDialog', AppDialog)
Vue.component('pageTitleBar', PageTitleBar)
Vue.component('appSectionLoader', AppSectionLoader)
Vue.component('appFileUpload', AppFileUpload)
Vue.component('appHeader', AppHeader)
Vue.component('VueEditor', VueEditor)
Vue.component('appQrEcard', AppQrEcard)
// Vue.component('wysiwyg', wysiwyg)

Vue.component('vue-vcard', Vuevcard)
Vue.component('qr-code', VueQRCodeComponent)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD0uDcGZyabRepW-mTtC2vqsi4Zwp_NzSk',
    libraries: 'places'
  }
})
